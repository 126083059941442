import React from 'react'

import { fieldDescription } from './styles.css'
type Props = {}

const FieldDescription: React.FC<Props> = ({ children }) => {
  return <span className={fieldDescription}>{children}</span>
}

export default FieldDescription

import BaseSvg, { Props } from '../BaseSvg'

const Eye: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M8 2c4.5858 0 6.8571 4.51285 6.8571 6 0 1.48715-2.2584 6-6.8571 6-4.59868 0-6.85714-4.49329-6.85714-6S3.4142 2 8 2Zm0 1.71429c-1.53567 0-2.83536.6739-3.85487 1.84584C3.36518 6.45669 2.85714 7.58691 2.85714 8c0 .41561.50597 1.54522 1.28394 2.4408C5.15869 11.6122 6.4586 12.2857 8 12.2857c1.53915 0 2.8387-.6756 3.8579-1.8511.7774-.89653 1.285-2.02809 1.285-2.4346 0-.40398-.5097-1.53616-1.2891-2.43372C10.8327 4.3903 9.53342 3.71429 8 3.71429Zm0 2.57142c.94677 0 1.71428.76752 1.71428 1.71429 0 .94677-.76751 1.71429-1.71428 1.71429S6.28571 8.94677 6.28571 8c0-.94677.76752-1.71429 1.71429-1.71429Z"
      fill="#1D1D1B"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Eye

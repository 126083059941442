import { Small } from '@casavo/base-ui'
import cn from 'classnames'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import getConfig from 'next/config'

import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'

import { footerPrivacyPath, footerTermsPath } from '../../i18n-routes'

type Props = {
  className?: string
}

export const DisclaimerRecaptcha: React.FC<Props> = ({ className }) => {
  const { lang } = useTranslation()
  const { publicRuntimeConfig } = getConfig()
  return (
    <Small
      alignment="center"
      className={cn(sprinkles({ marginX: 'auto' }), className)}
      color="greyscale500"
      data-testid="disclaimer-recaptcha"
    >
      <Trans
        components={[
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            key="1"
            className={sprinkles({ textDecoration: 'underline' })}
            href={`${publicRuntimeConfig.casavoWebsite}${footerPrivacyPath[lang]}`}
            rel="noopener noreferrer"
            target="_blank"
          />,
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            key="0"
            className={sprinkles({ textDecoration: 'underline' })}
            href={`${publicRuntimeConfig.casavoWebsite}${footerTermsPath[lang]}`}
            rel="noopener noreferrer"
            target="_blank"
          />,
        ]}
        i18nKey={'privacy:recaptcha.disclaimer'}
      />
    </Small>
  )
}

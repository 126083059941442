import { useField } from 'formik'
import React, { useCallback } from 'react'

import PhonePrefixSelect from '@/components/PhonePrefixSelect'

type Props = {
  handleTracking?: () => void
  name: string
  onClick?: () => void
  required?: boolean
}

const FormikPhonePrefix: React.FC<Props> = ({ handleTracking, name, onClick, required }) => {
  const [field] = useField({
    name,
    onClick,
    required,
  })
  const { onChange, value } = field
  const handleChange = useCallback(onChange, [onChange, name])

  return (
    <PhonePrefixSelect
      selected={value}
      onChange={(prefix) => {
        handleTracking && handleTracking()
        handleChange(name)(prefix)
      }}
    />
  )
}

export default FormikPhonePrefix

import useTranslation from 'next-translate/useTranslation'
import React, { useState } from 'react'
import { getCountries } from 'react-phone-number-input/input'

import Select from '@/components/Select'
import { langToCountryCode, getPrefixFromCountryCode } from '@/utils/prefix'

import { phonePrefixWrapper } from './styles.css'

const uniquePrefixes = Array.from(new Set(getCountries().map((country) => getPrefixFromCountryCode(country)))).sort()

type Props = {
  onChange: (prefix: any) => void
  selected?: string
}

const PhonePrefixSelect: React.FC<Props> = ({ onChange, selected }) => {
  const { lang } = useTranslation()
  const [selectedPrefix, setSelectedPrefix] = useState(selected || getPrefixFromCountryCode(langToCountryCode(lang)))

  return (
    <div className={phonePrefixWrapper} data-testid="prefix-div">
      <Select
        noBorder
        className="phone-prefix-select"
        dataTestId="prefix-select"
        options={uniquePrefixes.map((prefix) => ({
          label: prefix,
          value: prefix,
        }))}
        value={selectedPrefix}
        onSelect={({ value }) => {
          setSelectedPrefix(value)
          onChange(value)
        }}
      />
    </div>
  )
}
export default PhonePrefixSelect

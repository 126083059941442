import { CountryCode } from 'libphonenumber-js/min'
import { getCountryCallingCode } from 'react-phone-number-input/input'

export const langToCountryCode = (lang: Locale): CountryCode => {
  const codeFromLocale: Record<Locale, CountryCode> = {
    en: 'IT',
    fr: 'FR',
    it: 'IT',
  }

  return codeFromLocale[lang]
}

export const getPrefixFromCountryCode = (countryCode: CountryCode): string => `+${getCountryCallingCode(countryCode)}`

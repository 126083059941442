import { colors } from '@casavo/base-ui'
import { transparentize } from 'polished'

export const spacings = {
  '-2xl': '-48px',
  '-3xl': '-64px',
  '-4xl': '-80px',
  '-5xl': '-96px',
  '-l': '-24px',
  '-m': '-16px',
  '-m-l': '-20px',
  '-s': '-8px',
  '-s-m': '-12px',
  '-xl': '-32px',
  '-xs': '-4px',
  '-xxs': '-2px',
  '0': '0px',
  '2xl': '48px',
  '3xl': '64px',
  '4xl': '80px',
  '5xl': '96px',
  auto: 'auto',
  l: '24px',
  m: '16px',
  'm-l': '20px',
  s: '8px',
  's-m': '12px',
  xl: '32px',
  xs: '4px',
  xxs: '2px',
}

export const theme = {
  colors,
  fontWeights: {
    normal: 400,
  },
  shadows: {
    floating: `0 6px 24px 0 ${transparentize(0.7, colors.greyscale600)}`,
    lifted: `0 2px 8px 0 ${transparentize(0.7, colors.greyscale600)}`,
    raised: `0 4px 16px 0 ${transparentize(0.7, colors.greyscale600)}`,
    soft: `0 1px 4px 0 ${transparentize(0.7, colors.greyscale600)}`,
  },
  spacings,
}

export type Spacings = keyof typeof spacings

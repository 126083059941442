import { useField } from 'formik'
import React from 'react'

import FormikPhonePrefix from '@/components/FormFields/FormikPhonePrefix'
import TextInput from '@/components/TextInput'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'

export type FormikInputType = 'text' | 'email' | 'number' | 'tel' | 'password'

type BaseProps = {
  handleTracking?: () => void
  id?: string
  inputMode?: InputMode
  isPrivate?: boolean
  label: string
  name: string
  onClick?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  required?: boolean
  useWarning?: boolean
}

type NotTelProps = BaseProps & {
  handleTrackingPrefix?: never
  prefixName?: never
  type: Exclude<FormikInputType, 'tel'>
}

type TelProps = BaseProps & {
  handleTrackingPrefix?: () => void
  prefixName?: string
  type: 'tel'
}

type Props = NotTelProps | TelProps

const FormikInput: React.FC<Props> = ({
  handleTracking,
  handleTrackingPrefix,
  id,
  inputMode,
  isPrivate = true,
  label,
  name,
  onClick,
  onKeyDown,
  placeholder,
  prefixName,
  required,
  type,
  useWarning,
}) => {
  const [field, meta, helper] = useField({
    id,
    name,
    onClick,
    placeholder,
    required,
    type,
  })

  const { onBlur, onChange, value } = field
  const { error, touched } = meta
  const { setTouched } = helper

  const isTel = type === 'tel'

  return (
    <TextInput
      className={sprinkles({
        border: 0,
      })}
      dataTestId={name}
      error={!useWarning && touched && error && error}
      id={id}
      inputMode={inputMode}
      isPrivate={isPrivate}
      label={label}
      name={name}
      placeholder={placeholder}
      prefix={isTel && prefixName && <FormikPhonePrefix handleTracking={handleTrackingPrefix} name={prefixName} />}
      required={required}
      type={type as string}
      value={value}
      warning={useWarning && touched && error && error}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        onBlur(e)
        typeof handleTracking === 'function' && handleTracking()
      }}
      onChange={(e) => {
        type === 'password' && setTouched(true)
        onChange(e)
      }}
      onClick={onClick}
      onKeyDown={onKeyDown}
    />
  )
}

export default FormikInput

import { Caption } from '@casavo/base-ui'
import cn from 'classnames'
import React, { useRef, useState } from 'react'
import mergeRefs from 'react-merge-refs'

import FieldDescription from '@/components/FieldDescription'
import Label from '@/components/Label'
import Eye from '@/design-lib/components/Icons/Eye'
import { stateDecoration } from '@/design-lib/components/StateDecorator/style.css'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'

import {
  inputClass,
  labelClass,
  labelOuter,
  passwordInputPadding,
  prefixBorder,
  textInput,
  textInputState,
} from './styles.css'

export interface Props extends Omit<React.HTMLProps<HTMLInputElement>, 'prefix' | 'ref'> {
  dataTestId?: string
  description?: string | React.ReactNode
  error?: string | React.ReactNode
  hidePrefixBorder?: boolean
  htmlRequired?: boolean
  infoMessage?: string | React.ReactNode
  inputRef?: React.MutableRefObject<HTMLInputElement>
  isPrivate?: boolean
  label?: string
  noBorder?: boolean
  prefix?: string | React.ReactNode
  required?: boolean
  warning?: string | React.ReactNode
}

const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      dataTestId,
      description,
      disabled = false,
      error,
      hidePrefixBorder = false,
      htmlRequired = false,
      infoMessage,
      isPrivate = true,
      label,
      name,
      noBorder,
      onBlur,
      placeholder,
      prefix,
      required = false,
      type = 'text',
      warning,
      ...others
    },
    ref
  ) => {
    let state: 'INFO' | 'WARN' | 'ERR' | undefined
    if (!!infoMessage) {
      state = 'INFO'
    }
    if (!!warning) {
      state = 'WARN'
    }
    if (!!error) {
      state = 'ERR'
    }

    const [isFocused, setIsFocused] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const { className, ...othersNoClassName } = others
    const inputRef = useRef(null)

    const getBaseClass = (): string => {
      if (disabled) return textInput.disabled
      if (isFocused) return textInput.focused
      return textInput.default
    }

    const getStatusClass = (): string => {
      if (state === 'ERR') return textInputState.error
      if (state === 'WARN') return textInputState.warning
      return textInputState.undefined
    }

    const getInputClasses = (): string[] => {
      const classes: string[] = [inputClass.default]

      if (noBorder) classes.push(inputClass.noBorder)
      if (!label) classes.push(inputClass.withoutLabel)
      return classes
    }

    return (
      <>
        <div className={cn(getStatusClass(), getBaseClass())}>
          {prefix ? (
            <>
              {prefix}
              {!hidePrefixBorder && <div className={prefixBorder} />}
            </>
          ) : null}
          <div className={labelOuter}>
            <Label
              className={labelClass}
              filled={Boolean(others.value) || (Boolean(others.defaultValue) && !disabled)}
              htmlFor={name}
              label={label}
              required={required}
            />

            <input
              autoComplete="on"
              className={cn(
                getInputClasses(),
                isPrivate ? 'ca-sensitive' : '',
                type === 'password' ? passwordInputPadding : '',
                className
              )}
              {...othersNoClassName}
              ref={ref ? mergeRefs([inputRef, ref]) : inputRef}
              data-testid={dataTestId}
              disabled={disabled}
              id={name}
              name={name}
              placeholder={
                /* showing placeholder white disabled looks like there is content in the field*/
                disabled ? '' : placeholder
              }
              required={htmlRequired}
              type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
              onBlur={(e) => {
                if (onBlur) {
                  onBlur(e)
                }
                setIsFocused(false)
              }}
              onFocus={(e) => {
                others.onFocus && others.onFocus(e)
                setIsFocused(true)
              }}
            />
          </div>
          {type === 'password' && (
            <button
              className={sprinkles({
                alignItems: 'center',
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                padding: 'm',
                position: 'absolute',
                right: 0,
                top: 0,
              })}
              data-testid="input-show-password-toggle"
              type="button"
              onClick={() => inputRef.current.value && setShowPassword(!showPassword)}
            >
              <Eye />
            </button>
          )}
        </div>

        {infoMessage || warning || error || description ? (
          <div style={{ minHeight: 10 }}>
            {infoMessage && state === 'INFO' && (
              <div className={stateDecoration.info}>
                <Caption as="span" color="white" data-testid="state-info">
                  {infoMessage}
                </Caption>
              </div>
            )}
            {warning && state === 'WARN' && (
              <div className={stateDecoration.warn}>
                <Caption as="span" color="white" data-testid="state-warning">
                  {warning}
                </Caption>
              </div>
            )}
            {error && (
              <div className={stateDecoration.error}>
                <Caption as="span" color="white" data-scroll="error" data-testid="error">
                  {error}
                </Caption>
              </div>
            )}
            {description && typeof description === 'string' ? (
              <FieldDescription>{description}</FieldDescription>
            ) : (
              description
            )}
          </div>
        ) : null}
      </>
    )
  }
)

export default TextInput

import { Caption, Description } from '@casavo/base-ui'
import cn from 'classnames'
import { useEffect, useState } from 'react'

import ArrowUp from '@/components/Icon/ArrowUp'
import Label from '@/components/Label'
import { stateDecoration } from '@/design-lib/components/StateDecorator/style.css'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'

import {
  arrowIcon,
  disabledSelect,
  optionPanelWrapper,
  rightValueDescription,
  selectBase,
  selectDescription,
  selectLabel,
  selectOptionBase,
  selectOptionState,
  selectStatus,
} from './styles.css'

export type Option = { label: string; value: string }

export interface Props<O extends Option = Option>
  extends Omit<React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, 'onSelect'> {
  className?: string
  dataTestId?: string
  error?: string
  infoMessage?: string | React.ReactNode
  label?: string
  noBorder?: boolean
  onSelect: (option: O) => void
  options: O[]
  rightValue?: boolean
  warning?: string | React.ReactNode
}

function Select<O extends Option>({
  className,
  dataTestId,
  disabled,
  error,
  infoMessage,
  label,
  name,
  noBorder,
  onSelect,
  options,
  placeholder,
  required = false,
  rightValue,
  value,
  warning,
}: Props<O>) {
  const selectedOption = options.find((o) => o.value === value)
  const [isOptionPanelOpen, setIsOptionPanelOpen] = useState<boolean>(false)
  const [activeOption, setActiveOption] = useState<number>(-1)

  const handleOpenOptionPanel = () => {
    setIsOptionPanelOpen(!isOptionPanelOpen)
    setActiveOption(-1)
  }

  useEffect(() => {
    const handleListner = () => {
      setIsOptionPanelOpen(false)
      setActiveOption(-1)
    }

    document.addEventListener('click', handleListner)

    return () => document.removeEventListener('click', handleListner)
  }, [])

  const onKeyDown = (event: React.KeyboardEvent) => {
    const eventKey = event.key as 'Escape' | 'Enter' | 'ArrowUp' | 'ArrowDown' | 'Tab'
    if (eventKey === 'Escape') {
      setIsOptionPanelOpen(false)
    }
    if (options.length > 0 && (eventKey === 'Enter' || eventKey === 'Tab') && activeOption >= 0) {
      const selectedOption = options[activeOption]
      onSelect(selectedOption)
      setIsOptionPanelOpen(false)
    }
    if (eventKey === 'ArrowUp') {
      event.preventDefault()
      setActiveOption((prev) => prev - 1)

      if (activeOption < 1) {
        setActiveOption(options.length - 1)
      }
    }

    if (eventKey === 'ArrowDown') {
      event.preventDefault()
      setIsOptionPanelOpen(true)

      if (isOptionPanelOpen) {
        setActiveOption((prev) => prev + 1)

        if (activeOption === options.length - 1) {
          setActiveOption(0)
        }
      }
    }
  }

  let state: 'INFO' | 'WARN' | 'ERR' | undefined

  if (!!infoMessage) {
    state = 'INFO'
  }

  if (!!warning) {
    state = 'WARN'
  }

  if (!!error) {
    state = 'ERR'
  }

  const getSelectClasses = (): string[] => {
    const appliedClasses = [noBorder ? selectBase.noBorder : selectBase.default]

    if (disabled) appliedClasses.push(disabledSelect)
    if (typeof state === 'undefined') appliedClasses.push(selectStatus.undefined)
    if (state === 'INFO') appliedClasses.push(selectStatus.info)
    if (state === 'WARN') appliedClasses.push(selectStatus.warn)
    if (state === 'ERR') appliedClasses.push(selectStatus.err)

    return appliedClasses
  }

  return (
    <>
      <div className={sprinkles({ position: 'relative' })}>
        <div
          className={cn(className, getSelectClasses())}
          data-testid={`${dataTestId}-div`}
          role="listbox"
          tabIndex={0}
          onClick={(e) => {
            e.stopPropagation()
            handleOpenOptionPanel()
          }}
          onKeyDown={onKeyDown}
        >
          {label && (
            <Label
              className={rightValue ? selectLabel.rightValue : selectLabel.default}
              filled={Boolean(value) && !disabled}
              htmlFor={name}
              label={label}
              required={required}
            />
          )}
          <Description
            className={cn(
              label ? selectDescription.default : selectDescription.noLabel,
              rightValue ? rightValueDescription : ''
            )}
            data-testid="selected-option"
          >
            {selectedOption ? selectedOption.label : placeholder}
          </Description>

          <ArrowUp className={disabled ? arrowIcon.disabled : arrowIcon.default} />

          {isOptionPanelOpen && (
            <div className={optionPanelWrapper}>
              {options.map((opt, index) => {
                const isSelected = value === opt.value
                const isHover = activeOption === index

                return (
                  // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                  <div
                    key={index}
                    // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                    aria-selected={isSelected}
                    className={cn(
                      selectOptionBase,
                      isHover ? selectOptionState.hover : '',
                      isSelected ? selectOptionState.selected : ''
                    )}
                    data-testid={opt.value}
                    role="option"
                    onClick={(e) => {
                      e.stopPropagation()
                      onSelect(opt)
                      setIsOptionPanelOpen(false)
                    }}
                    onFocus={() => setActiveOption(index)}
                    onMouseOver={() => setActiveOption(index)}
                  >
                    {opt.label}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>

      {error && state === 'ERR' && (
        <Caption as="span" className={stateDecoration.error} color="white" data-testid="state-error">
          {error}
        </Caption>
      )}

      {warning && state === 'WARN' && (
        <div className={stateDecoration.warn}>
          <Caption as="span" color="white" data-testid="state-warning">
            {warning}
          </Caption>
        </div>
      )}

      {infoMessage && state === 'INFO' && (
        <div className={stateDecoration.info}>
          <Caption as="span" color="white" data-testid="state-info">
            {infoMessage}
          </Caption>
        </div>
      )}

      <select
        className={sprinkles({ display: 'none' })}
        data-testid={dataTestId || 'select'}
        id={name}
        value={value}
        onChange={() => {}}
      >
        {options.map((opt, index) => (
          <option key={index} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    </>
  )
}

export default Select

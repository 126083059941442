import "src/components/Select/styles.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/Select/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41VwW7bMAy99ysEDAUyoMqkxG5a5da1/YedBsVSbK2KJEhy6nbovw+S3Vh27KxxciEf6UfykVn+3ry/SSoR+HsFwE5bxi0B2DTAaSnY9mSEXhso+d5DS5moHQGZaUZuK8rqzF/U1mlLgNFCeW6DSQrFYcUDmgAcLAehTobsrg00lDGhyvhSAkZG22LXq86snfBCKwIsl9SLIw/W2nELHZe88AQorfj26uNq2VWMk4phoWXgaMsdXazub8J3c4OW6++DCLLXRe0m4r7hR/yIH1LwagLmLVXOUMuVH0Bn884ErFsoLV5Kq2vFTjSe8/C0DYndhvzIlXfn1Wfp23bae324NN0OMTHgPmX+tcbcTsGen3+ibJ3CNlOwp4d8k28GsLPeJeqdCriL0F4vdOe0rH3Ui9eGgNWwKhrxNpVm79xF57hWAA7UlkJ1XYuEEmurZxQs+sjtXupXAirBGFfbeWq88bCHcymFccIF12slPIfO0IKHOb9aaqJZMF8RUFBZLDBC1wDGbRkouuj61tIcFceiM/YkR9eRRJDjXttDp0xJPf+1gDm6HmTlbYuHm4q7TY2FUCnKsKvBlUbuB3zQfDu6pN1R+CJHYLWnni/wHWK8HFAuJwfZ+6uBP1/lqxynfjG7kfGTbNLZfjXQVZSFodpyt0AgPD8ADnyRaU4/bJqbaUQWvDhJJ96FKkm/urNnuBfigTan+7tGqE32KTf4RgCtvU7v7+eMsuFZjpPIxjd5YnLoNLfPc/8OhWK8iX8IfWP//L+xSRkdDQLwrWnGen65kCp/ut9eFICcC+6Xfszs4x9ZO/8SXAcAAA==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var arrowIcon = {'default':'_7zylalg _7zylalf',disabled:'_7zylalh _7zylalf'};
export var disabledSelect = '_7zylal3';
export var optionPanelWrapper = '_7zylali';
export var rightValueDescription = '_7zylale';
export var selectBase = {'default':'_7zylal1 _7zylal0',noBorder:'_7zylal2 _7zylal0'};
export var selectDescription = {'default':'_7zylalc _7zylalb',noLabel:'_7zylald _7zylalb'};
export var selectLabel = {'default':'_7zylal8',rightValue:'_7zylala _7zylal8'};
export var selectOptionBase = '_7zylalj';
export var selectOptionState = {hover:'_7zylalk',selected:'_7zylall'};
export var selectStatus = {undefined:'_7zylal4',info:'_7zylal5',warn:'_7zylal6',err:'_7zylal7'};
import axios from 'axios'

export const isValidUserFor = async (action: string): Promise<void> => {
  const token = await window.grecaptcha?.enterprise.execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, {
    action,
  })
  const options = {
    data: {
      action,
      token,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_LISA_WEBSITE}/api/recaptcha/`,
  }
  const response = await axios(options)
  const data = response.data
  const score = data?.score
  if (score < 0.6) {
    throw new Error(data.reason)
  }
}

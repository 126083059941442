import { TranslationQuery } from 'next-translate'
import Trans from 'next-translate/Trans'

import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'

const defaultComponents = {
  b: <strong style={{ fontWeight: 600 }} />,
  br: <br />,
  i: <em />,
  u: <u />,
}

export type ComponentMap = Record<string, React.ReactNode>

type Props = {
  components?: ComponentMap
  i18Key: string
  values?: TranslationQuery
}

export const Translated: React.FC<Props> = ({ components = null, i18Key, values = null }) => {
  return (
    <span className={sprinkles({ whiteSpace: 'pre-wrap' })}>
      <Trans
        components={{
          ...defaultComponents,
          ...components,
        }}
        i18nKey={i18Key}
        values={values}
      />
    </span>
  )
}

import { BodyM, Description } from '@casavo/base-ui'
import cn from 'classnames'
import React, { PropsWithChildren, ReactNode } from 'react'

import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { theme } from '@/design-lib/theme'

import { toastRecipes } from './styles.css'

type StyleType = 'default' | 'success' | 'warning' | 'error'
export type Props = {
  className?: string
  customIcon?: ReactNode
  description?: string
  styleType?: StyleType
  title: string
}

export const toastAnimation = {
  animate: {
    opacity: 1,
    transition: {
      duration: 0.4,
    },
    y: 0,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.4,
    },
    y: 10,
  },
  initial: { opacity: 0, y: 10 },
}

function getIcon(st: StyleType): React.ReactNode {
  switch (st) {
    case 'error':
      return (
        <svg fill="none" height="16" role="img" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M7.99992 1.33333C11.6818 1.33333 14.6666 4.3181 14.6666 8C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8C1.33325 4.3181 4.31802 1.33333 7.99992 1.33333ZM7.99992 3C5.23849 3 2.99992 5.23857 2.99992 8C2.99992 10.7614 5.23849 13 7.99992 13C10.7613 13 12.9999 10.7614 12.9999 8C12.9999 5.23857 10.7613 3 7.99992 3ZM9.76768 5.05372L10.9462 6.23223L9.17864 7.99955L10.9462 9.76777L9.76768 10.9463L7.99947 9.17872L6.23215 10.9463L5.05364 9.76777L6.82114 7.99955L5.05364 6.23223L6.23215 5.05372L7.99947 6.82122L9.76768 5.05372Z"
            fill={theme.colors.error600}
            fillRule="evenodd"
          />
        </svg>
      )
    case 'success':
      return (
        <svg fill="none" height="12" role="img" viewBox="0 0 14 12" width="14" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M12.3483 0L13.6666 1.1916L5.00688 12L0.333252 7.82676L1.44716 6.41938L4.80321 9.41572L12.3483 0Z"
            fill={theme.colors.success600}
            fillRule="evenodd"
          />
        </svg>
      )
    case 'warning':
      return (
        <svg fill="none" height="16" role="img" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M8.65765 2C10.0343 2 11.2342 2.93689 11.5681 4.27239L13.534 12.1362C13.735 12.9399 13.2463 13.7543 12.4426 13.9552C12.3236 13.985 12.2015 14 12.0788 14H3.92114C3.09272 14 2.42114 13.3284 2.42114 12.5C2.42114 12.3774 2.43618 12.2552 2.46593 12.1362L4.43188 4.27239C4.76575 2.93689 5.9657 2 7.34231 2H8.65765ZM8.65765 3.5H7.34231C6.69449 3.5 6.12492 3.91496 5.92098 4.52048L5.88709 4.6362L3.92114 12.5H12.0788L10.1129 4.6362C9.95575 4.00773 9.41504 3.5558 8.77813 3.50481L8.65765 3.5ZM7.99998 9.125C8.6213 9.125 9.12498 9.62868 9.12498 10.25C9.12498 10.8713 8.6213 11.375 7.99998 11.375C7.37866 11.375 6.87498 10.8713 6.87498 10.25C6.87498 9.62868 7.37866 9.125 7.99998 9.125ZM8.74998 4.625V8.375H7.24998V4.625H8.74998Z"
            fill={theme.colors.warning600}
            fillRule="evenodd"
          />
        </svg>
      )
    default:
      return (
        <svg fill="none" height="16" role="img" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M8.00004 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8C1.33337 4.3181 4.31814 1.33333 8.00004 1.33333ZM8.00004 3C5.23862 3 3.00004 5.23857 3.00004 8C3.00004 10.7614 5.23862 13 8.00004 13C10.7615 13 13 10.7614 13 8C13 5.23857 10.7615 3 8.00004 3ZM8.83337 7.58333V11.75H7.16671V7.58333H8.83337ZM8.00004 4.25C8.6904 4.25 9.25004 4.80964 9.25004 5.5C9.25004 6.19035 8.6904 6.75 8.00004 6.75C7.30968 6.75 6.75004 6.19035 6.75004 5.5C6.75004 4.80964 7.30968 4.25 8.00004 4.25Z"
            fill={theme.colors.lightBlue}
            fillRule="evenodd"
          />
        </svg>
      )
  }
}

export const Toast: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  customIcon,
  description,
  styleType,
  title,
}) => {
  const icon = getIcon(styleType)
  return (
    <div className={cn(toastRecipes({ style: styleType }), className)} data-testid={`toast-message-${styleType}`}>
      <div className={sprinkles({ display: 'flex' })}>
        {customIcon ? (
          <div className={sprinkles({ alignSelf: 'center', flexShrink: 0, marginRight: 'm' })}>{customIcon}</div>
        ) : icon ? (
          <div className={sprinkles({ alignSelf: 'center', flexShrink: 0, marginRight: 'm' })}>{icon}</div>
        ) : null}

        <div className={sprinkles({ display: 'flex', flexDirection: 'column' })}>
          <BodyM strong>{children || title}</BodyM>
          {description && <Description>{description}</Description>}
        </div>
      </div>
    </div>
  )
}
